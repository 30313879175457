import './services.orders'
import './services.family-mart'
import './services.tracker'

import { CHECKOUT_TRACKER_TYPE } from '../constants/tracker';

app.directive("checkoutDeliveryFamilyMart", [
  '$compile'
  '$filter'
  'ordersService'
  'familyMartService'
  'mainConfig'
  'trackerService'
  '$q'
  (
    $compile
    $filter
    ordersService
    familyMartService
    mainConfig
    trackerService
    $q
  ) ->
    {
      restrict: 'A'
      scope: {
        getFormData: "&"
      }
      link: (scope, element, attrs) ->
        scope.pickStore = () ->
          data = scope.getFormData()

          trackerPromiseWithDelay = () ->
            return new Promise((resolve) ->
              # shoplytics track
              trackerService.track({
                type: CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_ADDRESS_TYPE,
                data: {
                  event_category: 'Checkout',
                  delivery_type: 'cvs_pickup',
                  delivery_type_detail: data.order.delivery_option.region_type,
                },
              })
              # delay for tracker
              setTimeout(resolve, 500)
            )
          $q.all([ordersService.saveSessionData(data.order, {
              "save_fields_phone": data.saveFields.phone,
              "save_fields": data.saveFields,
              "order": {
                "custom_fields_translations": data.order.custom_fields_translations
              }
              # "save_user_fields": data.save_user_fields
            }),trackerPromiseWithDelay()]).then(([saveSessionDataResponse])->
              { data, status } = saveSessionDataResponse
              if attrs.deliveryType == 'freeze'
                $('#family_mart_freeze_map_selector').submit()
              else
                if mainConfig.familyMartEnv != 'prod'
                  familyMartService.getDomainToken()
                    .then (data) ->
                      $('#family_mart_map_selector').find('input[name=cvstemp]:first').attr('value', data.data.token)
                      $('#family_mart_map_selector').submit()
                    .catch (error) ->
                      logger.log(error)
                else
                  $('#family_mart_map_selector').submit()
            )
          return

        $container = element
        if $container.length == 1
          $container.find(".btn-pick-store").attr("ng-click", "pickStore()")
          $container.html($compile($container.html())(scope))
    }
])
