import './services.feature'
import './services.checkout.js.coffee'
import './services.address-preference'
import './multi_checkout/services.multi-checkout'
import './service.intl-tel-input'
import './services.tracker'

import { isEmpty, omit, debounce } from 'lodash-es';
import { CHECKOUT_EVENTS } from '../constants/angular-events';
import { CHECKOUT_TRACKER_TYPE, TRACKER_DEBOUNCE_DURATION } from '../constants/tracker';

app.directive("checkoutDeliveryForm", [
  '$compile'
  '$timeout'
  '$rootScope'
  'mainConfig'
  'featureService'
  'checkoutService'
  'storePickupOptions'
  'storePickupConfig'
  'addressPreferenceService'
  'storePickupAddresses'
  'multiCheckoutService'
  'intlTelInputService'
  'deliveryInfo'
  'trackerService'
  (
    $compile
    $timeout
    $rootScope
    mainConfig
    featureService
    checkoutService
    storePickupOptions
    storePickupConfig
    addressPreferenceService
    storePickupAddresses
    multiCheckoutService
    intlTelInputService
    deliveryInfo
    trackerService
  ) ->
    {
      restrict: 'A'
      scope: false
      link: (scope, element, attrs) ->
        scope.state = {
          syncRecipientCustomer: false
        }
        scope.savedAddress = []
        scope.saveFields = {
          default_address: false,
          delivery_address: false
        }
        disableAddressTimer = null

        handleTrackerEvent = (type) ->
          # shoplytics track
          trackerService.track({
            type: type,
            data: {
              event_category: 'Checkout',
            },
          })

        updateDeliveryData = (address, recipientData = {}) ->
          $rootScope.$broadcast('checkout.address_module.auto_fill', address, true)
          checkoutService.getElement('order[delivery_data][recipient_name]').val(recipientData.recipient_name)
          checkoutService.getElement('order[delivery_data][recipient_phone]').val(recipientData.recipient_phone)

          # shoplytics track
          if recipientData.recipient_name
            handleTrackerEvent(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_NAME_INPUT)
          if recipientData.recipient_phone
            handleTrackerEvent(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_PHONE_INPUT)

          if $(checkoutService.recipientIntlKey).length > 0
            if recipientData.recipient_phone_country_code
              intlTelInputService.setCountry('number', $(checkoutService.recipientIntlKey), recipientData.recipient_phone_country_code)
              intlTelInputService.setPhone('complete', $(checkoutService.recipientIntlKey), recipientData.recipient_phone)
            else if recipientData.recipient_phone
              intlTelInputService.reset($(checkoutService.recipientIntlKey))
            else
              intlTelInputService.setCountry('abbr', $(checkoutService.recipientIntlKey), mainConfig.requestCountry.toLowerCase())

        getSavedAddress = () ->
          addressPreferenceService.getAddressPreferenceData({
            country: deliveryInfo.country,
            scope: addressPreferenceService.PREFERENCE_SCOPE.INPUT_WITHOUT_COUNTRY,
          })
            .then((preferenceData) -> 
              deliveryInfo.savedAddress.map((deliveryData) ->
                addressPreferenceService.transformDeliveryDataToAddress({ 
                  country: deliveryInfo.country,
                  deliveryData: omit(deliveryData, ['recipient_name', 'recipient_phone', 'recipient_phone_country_code']),
                  preferenceData 
                })
            ))
            .then((savedAddress) -> 
              scope.savedAddress = savedAddress
              if !deliveryInfo.isCreatingNewAddress
                updateDeliveryData(savedAddress[deliveryInfo.selectedAddressIndex], deliveryInfo.savedAddress[deliveryInfo.selectedAddressIndex])
            )

        if !multiCheckoutService.isEnabled() && deliveryInfo.showSavedAddresses && deliveryInfo.savedAddress.length > 0
          $rootScope.$on('address_module.template.ready', (event, preferenceScope) -> 
            if preferenceScope != addressPreferenceService.PREFERENCE_SCOPE.DISPLAY
              getSavedAddress()
          )

        scope.storePickupOptions = storePickupOptions
        scope.storePickupConfig = storePickupConfig
        scope.storePickupAddresses = storePickupAddresses
        notifyForRecipientSync = (isChecked) ->
          scope.state.syncRecipientCustomer = isChecked
          $rootScope.$broadcast "checkout.customer.needs_update" if isChecked

        handleTrackerEventDebounced = debounce(handleTrackerEvent, TRACKER_DEBOUNCE_DURATION)

        element.on "click", "input[name='order[delivery_data][recipient_is_customer]']", (event) ->
          isChecked = angular.element(event.currentTarget).is(":checked")
          notifyForRecipientSync(isChecked)

          if isChecked
            handleTrackerEvent(CHECKOUT_TRACKER_TYPE.CHECKOUT_SAME_RECIPIENT_CLICK)
            handleTrackerEvent(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_NAME_INPUT)
            handleTrackerEvent(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_PHONE_INPUT)

        scope.$on "checkout.customer.changed", ($event, payload) ->
          if scope.state.syncRecipientCustomer
            checkoutService.getElement('order[delivery_data][recipient_name]').val(payload.customer_name)
            checkoutService.getElement('order[delivery_data][recipient_phone]').val(payload.customer_phone)
            if $(checkoutService.recipientIntlKey).length > 0
              if payload.customer_country
                intlTelInputService.setCountry('abbr', $(checkoutService.recipientIntlKey), payload.customer_country);
              else
                intlTelInputService.reset($(checkoutService.recipientIntlKey));

        element.on 'input', 'input[name="order[delivery_data][recipient_name]"], input[name="order[delivery_data][recipient_phone]"]', (event) ->
          disableSyncRecipientCustomer()
          if event.target.name == 'order[delivery_data][recipient_name]'
            handleTrackerEventDebounced(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_NAME_INPUT)
          
          if event.target.name == 'order[delivery_data][recipient_phone]'
            handleTrackerEventDebounced(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_PHONE_INPUT)

        element.on('countrychange', checkoutService.recipientIntlKey, () ->
          customerIntlElement = $(checkoutService.customerIntlKey)
          recipientIntlElement = $(checkoutService.recipientIntlKey)
          customerCountryCode = if customerIntlElement.length > 0 then intlTelInputService.getCountry('abbr', customerIntlElement) else ''
          recipientCountryCode = if recipientIntlElement.length > 0 then intlTelInputService.getCountry('abbr', recipientIntlElement) else ''
          if customerCountryCode != recipientCountryCode
            disableSyncRecipientCustomer()
        )

        $rootScope.$on CHECKOUT_EVENTS.CART.SUMMARY.UPDATED, () ->
          if scope.state.isChangingAddress
            scope.state.isChangingAddress = false
        
        tempDisableChangingAddressFromSavedaddress = ->
          scope.state.isChangingAddress = true
          disableAddressTimer = $timeout(()->
            scope.state.isChangingAddress = false
          , 500)

        element.on "change", "input[name='form[address]']", (event) ->
          tempDisableChangingAddressFromSavedaddress()
          target = angular.element(event.currentTarget)
          value = target.val()
          if value is 'new'
            updateDeliveryData()
            if ($('.dynamic-delivery-fee').length > 0)
              $timeout.cancel(disableAddressTimer)
              $rootScope.$broadcast CHECKOUT_EVENTS.CART.DELIVERY.RECALCULATE_FEE, { read_only: true }
          else
            updateDeliveryData(scope.savedAddress[value], deliveryInfo.savedAddress[value])
            if ($('.dynamic-delivery-fee').length > 0)
              $timeout.cancel(disableAddressTimer)
              addressNodeData = parseAndSaveLogisticCodeData(JSON.stringify(deliveryInfo.savedAddress[value]))
              if (addressNodeData && addressNodeData.logistic_codes)
                params = { delivery_address: { logistic_codes: addressNodeData.logistic_codes } }
                $rootScope.$broadcast CHECKOUT_EVENTS.CART.DELIVERY.UPDATE_FEE, params
          $rootScope.$broadcast 'tax.fee.address.changed'

        scope.$watch('saveFields.default_address', (val) ->
          if val
            scope.saveFields.delivery_address = true
        )

        element.on "destroy", (() -> element.off()) # Unbind events
        notifyForRecipientSync(checkoutService.getElement('order[delivery_data][recipient_is_customer]').is(":checked"))

        disableSyncRecipientCustomer = ->
          if angular.element('input[name="order[delivery_data][recipient_is_customer]"]').is(':checked')
            checkoutService.getElement('order[delivery_data][recipient_is_customer]').prop('checked', false)
            scope.state.syncRecipientCustomer = false

        $timeout(()->
          $rootScope.scrollTo($('.delivery-form')) if $('span[name="order[delivery_data][location_code]"]').text()
        ,0)

        parseAndSaveLogisticCodeData = (addressNodeData) ->
          try
            parsedData = JSON.parse(addressNodeData)
            if (parsedData && parsedData.logistic_code)
              $("#dynamic-deliver-fee-logistic-code").val(parsedData.logistic_code)
            return parsedData
          catch e
            console.log(e)

        $(window).load(() -> 
          if ($('.dynamic-delivery-fee').length > 0)
            try
              params = { status: 'formDefaultAddressCall', read_only: true }
              if !isEmpty(deliveryInfo.recipientData)
                addressNodeData = parseAndSaveLogisticCodeData(JSON.stringify(deliveryInfo.recipientData))
              else if !multiCheckoutService.isEnabled() && deliveryInfo.showSavedAddresses && deliveryInfo.savedAddress.length > 0
                addressNodeData = parseAndSaveLogisticCodeData(JSON.stringify(deliveryInfo.savedAddress[0]))
              else
                $("#dynamic-deliver-fee-logistic-code").val("")

              if (addressNodeData && addressNodeData.logistic_codes)
                params = { delivery_address: { logistic_codes: addressNodeData.logistic_codes } }
              $rootScope.$broadcast CHECKOUT_EVENTS.CART.DELIVERY.UPDATE_FEE, params
            catch e
              console.log(e)
          
          $(".dynamic-delivery-fee-state-select").change(() ->
            try
              addressNode = JSON.parse($(this).val())
              $('input[name="order[delivery_address][state]"').val(addressNode.name)
              $("#dynamic-deliver-fee-logistic-code").val(addressNode.logistic_code)
              $rootScope.$broadcast CHECKOUT_EVENTS.CART.DELIVERY.UPDATE_FEE
            catch e
              $rootScope.$broadcast CHECKOUT_EVENTS.CART.DELIVERY.RECALCULATE_FEE
          )
        )

        scope.onTimeSlotChange = (payload) ->
          scope.multiCartData[payload.cartTagId].timeSlot = {
            ...scope.multiCartData[payload.cartTagId],
            ...omit(payload, 'cartTagId')
          }

        scope.multiCheckoutService = multiCheckoutService;

        # check if session has data
        initialCheckRecipientValue = _.once(() ->
          handleTrackerEvent(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_NAME_INPUT)
          handleTrackerEvent(CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_PHONE_INPUT)
        )

        initialCheckRecipientValue()
    }
])