import './services.tcat_seven_eleven';
import './services.orders';
import './services.tracker';

import { CHECKOUT_TRACKER_TYPE } from '../constants/tracker';

app.directive('checkoutDeliverySevenEleven', [
  '$compile',
  '$filter',
  'ordersService',
  'tcatSevenElevenService',
  'trackerService',
  '$q',
  (
    $compile,
    $filter,
    ordersService,
    tcatSevenElevenService,
    trackerService,
    $q,
  ) => {
    return {
      restrict: 'A',
      scope: {
        getFormData: '&',
      },
      link: function (scope, element, attrs) {
        scope.pickStore = function () {
          const data = scope.getFormData();

          const trackerPromiseWithDelay = () =>
            new Promise((resolve) => {
              // shoplytics track
              trackerService.track({
                type: CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_ADDRESS_TYPE,
                data: {
                  event_category: 'Checkout',
                  delivery_type: 'cvs_pickup',
                  delivery_type_detail: data.order.delivery_option.region_type,
                },
              });
              // delay for tracker
              setTimeout(resolve, 500);
            });

          $q.all([
            ordersService.saveSessionData(data.order, {
              save_fields_phone: data.saveFields.phone,
              save_fields: data.saveFields,
              order: {
                custom_fields_translations:
                  data.order.custom_fields_translations,
              },
            }),
            trackerPromiseWithDelay(),
          ]).then(() => {
            if (attrs.deliveryType === 'crossBorder') {
              $('#seven_eleven_cross_border_selector').submit();
              return;
            }
            if (
              tcatSevenElevenService.isTcatSevenElevenEmapType(attrs.regionType)
            ) {
              $('#tcat_seven_eleven_map_selector').submit();
              return;
            }
            $('#seven_eleven_map_selector').submit();
          });
        };

        if (element.length === 1) {
          element.find('.btn-pick-store').attr('ng-click', 'pickStore()');
          element.html($compile(element.html())(scope));
        }
      },
    };
  },
]);
